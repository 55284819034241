import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { gsap } from 'gsap'
import { AnimationAction } from 'three'

/**
 * Canvas 
**/

const canvas = document.querySelector('canvas.webgl');

/**
 * Scene
**/

const scene = new THREE.Scene();

/**
 * Overlay
**/

const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1);
const overlayMaterial = new THREE.ShaderMaterial({
    transparent: true,
    uniforms:{
        uAlpha: { value: 1}
    },
    vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform float uAlpha;
        void main()
        {
            gl_FragColor = vec4(0.8313, 0.9450, 1, uAlpha);
        }
    `
});
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial);
scene.add(overlay);

/**
 * Sizes
**/

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

/**
 * Camera
**/

const aspectRatio = sizes.width / sizes.height
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 1000)
//Orthographic Camera
//const zoomFaktor = 3;
//const camera = new THREE.OrthographicCamera(-zoomFaktor * aspectRatio,zoomFaktor * aspectRatio,zoomFaktor,-zoomFaktor, 0.1, 1000)
camera.position.set(-5,5,5)
scene.add(camera)

/**
 * Controls
**/

const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true
controls.minPolarAngle = Math.PI/180*30;
controls.maxPolarAngle = Math.PI/180*80;      // 80° von oben nach unten
controls.screenSpacePanning = false;
controls.dampingFactor = 0.1;
controls.enablePan = false;
controls.target = new THREE.Vector3(0,0.8,0);
controls.autoRotateSpeed = 0.2;

//Orthographic Camera
/* controls.maxZoom = 1;   //wie nah
controls.minZoom = 0.2; // wie weit, kleiner = weiter */

//Perspektive Camera
controls.minDistance = 9;
controls.maxDistance = 9;

/**
 * Renderer
**/

const backColor = {
    color: 0xd4f1ff
}
// gui.addColor(backColor, 'color')

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.shadowMap.enabled = true
//renderer.shadowMap.type = THREE.PCFSoftShadowMap


/**
 * Window Listener
**/

window.addEventListener('resize', () => 
{
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    camera.aspect = sizes.width / sizes.height
    // aspectRatio = sizes.width / sizes.height
    // camera.left = -zoomFaktor * aspectRatio
    // camera.right= zoomFaktor * aspectRatio
    // camera.top  = zoomFaktor
    // camera.bottom = -zoomFaktor
    camera.updateProjectionMatrix()

    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

window.addEventListener('dblclick', () => 
{
    if(!document.fullscreenElement)
    {
        canvas.requestFullscreen()
    }
    else
    {
        document.exitFullscreen()
    } 
})

/*################################Scene-Content################################*/

/**
 *  Lights
 */

// Ambient Light 
const ambientLight = new THREE.AmbientLight(0xffffff, 0.6)
scene.add(ambientLight)
// gui.add(ambientLight, 'intensity').min(0).max(1).step(0.001)

// Directional Light
const directionalLight = new THREE.DirectionalLight(0xffffff, 1.5)
directionalLight.position.set(-2,2.5,3.5)
// gui.add(directionalLight, 'intensity').min(0).max(2).step(0.001)
// gui.add(directionalLight.position, 'x').min(- 5).max(10).step(0.001)
// gui.add(directionalLight.position, 'y').min(- 5).max(10).step(0.001)
// gui.add(directionalLight.position, 'z').min(- 5).max(10).step(0.001)
scene.add(directionalLight)

directionalLight.castShadow = true;

directionalLight.shadow.mapSize.width = 1024*4
directionalLight.shadow.mapSize.height = 1024*4
directionalLight.shadow.camera.near = 0
directionalLight.shadow.camera.far = 10
directionalLight.shadow.camera.top    = 5
directionalLight.shadow.camera.right  = 5
directionalLight.shadow.camera.bottom = -5
directionalLight.shadow.camera.left   = -5
directionalLight.shadow.bias =  - 0.001
directionalLight.shadow.radius = 10

const directionalLightCameraHelper = new THREE.CameraHelper(directionalLight.shadow.camera)
directionalLightCameraHelper.visible = true
//scene.add(directionalLightCameraHelper)

// Directional Light
const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.5)
directionalLight2.position.set(-1.5, 1.5, -4)
// gui.add(directionalLight2, 'intensity').min(0).max(2).step(0.001)
// gui.add(directionalLight2.position, 'x').min(- 5).max(10).step(0.001)
// gui.add(directionalLight2.position, 'y').min(- 5).max(10).step(0.001)
// gui.add(directionalLight2.position, 'z').min(- 5).max(10).step(0.001)
scene.add(directionalLight2)

const directionalLightHelper = new THREE.DirectionalLightHelper(directionalLight2,0.4)
//scene.add(directionalLightHelper)

/**
 * Objects
 */

// Groundfloor
const floorGeo  = new THREE.BoxGeometry(400, 0.01, 400);
const floorMat  = new THREE.MeshStandardMaterial();
floorMat.color  = new THREE.Color("rgb(247, 239, 229)"); // creme
//floorMat.wireframe = true;
const floorMesh = new THREE.Mesh(floorGeo, floorMat);
//scene.add(floorMesh);
floorMesh.receiveShadow = true;

// HDRI Sphere
const sphereGeo = new THREE.SphereGeometry( 200, 60, 40 );
sphereGeo.scale( - 1, 1, 1 );
const sphereMesh = new THREE.Mesh(sphereGeo, floorMat);
//scene.add(sphereMesh);

// Test Cube
const testGeo1m = new THREE.BoxGeometry(1, 1, 1);
const testMat1m = new THREE.MeshStandardMaterial();
testMat1m.color = new THREE.Color("rgb(255, 0, 0)");
const testMesh1m = new THREE.Mesh(testGeo1m, testMat1m);
testMesh1m.position.set(-3.0, 0.5, 0) ;
testMesh1m.castShadow = true;
//scene.add(testMesh1m);

/**
 * Loading 
**/

// Loading Manager
const loadingBarElement = document.querySelector('.loading-bar')
const loadingManager = new THREE.LoadingManager(
    // Loaded
    () =>
    {
        window.setTimeout(() =>
        {
            gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })
            loadingBarElement.classList.add('ended')
            loadingBarElement.style.transform = ''   
            
            var body = document.body; // Safari
            var html = document.documentElement; // Chrome, Firefox, IE and Opera places the overflow at the <html> level, unless else is specified. Therefore, we use the documentElement property for these browsers
            body.scrollTop = 0;
            html.scrollTop = 0;

        }, 500)
        controls.minDistance = 9;
        controls.maxDistance = 12; 
        window.setTimeout(() =>
        {
        controls.autoRotate = true;
        //gsap.to(pivot.position, { duration:4, x:1.2, z:1.2})
        }, 500)

        window.setTimeout(() =>
        {
        document.getElementsByClassName("contact")[0].style.display = 'block';
        }, 2000)
        

    },
    // Progress
    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        const progressRatio = itemsLoaded / itemsTotal
        loadingBarElement.style.transform = `scaleX(${progressRatio})`
    }
);

// GLTF Loader
var gltfLoader = new GLTFLoader(loadingManager)

//const material4 = new THREE.MeshNormalMaterial()
const material4 = new THREE.MeshPhongMaterial()
//material4.wireframe = true
//material4.side = THREE.DoubleSide
var loadID = 0
var pivot = new THREE.Group();
scene.add(pivot);

//Moebel2
gltfLoader.load(
    '/models/lemontree.glb',
    (gltf) =>
    {   
        loadID = gltf.scene.id;        
        // zentrieren
        var box = new THREE.Box3().setFromObject( gltf.scene );
        var center = box.getCenter( new THREE.Vector3() );
        
        gltf.scene.position.x += ( gltf.scene.position.x - center.x );
        //gltf.scene.position.y += ( gltf.scene.position.y - center.y );
        gltf.scene.position.z += ( gltf.scene.position.z - center.z );
        gltf.scene.position.y = 2
        //gltf.scene.position.multiplyScalar( - 1 );
        const children = [...gltf.scene.children]
        for(const child of children)
        {
            child.castShadow = true;
            child.receiveShadow = true;
            pivot.add(child)
            child.material.roughness = 0.75
            child.material.metalness = 0
        }
      
        var stamm = scene.getObjectByName( "Vert001", true );
        const baumMat  = new THREE.MeshStandardMaterial();
        baumMat.color  = new THREE.Color("rgb(122, 80, 58)"); 
        stamm.material = baumMat;

        var gras = scene.getObjectByName( "Icosphere002", true );
        const grasMat = new THREE.MeshStandardMaterial();
        grasMat.color = new THREE.Color("rgb(122, 151, 101)"); 
        gras.material = grasMat;
        
        var erde = scene.getObjectByName( "Icosphere003", true );
        const erdeMat = new THREE.MeshStandardMaterial();
        erdeMat.color = new THREE.Color("rgb(127,111,93)"); 
        erde.material = erdeMat;        
    }
)


gltfLoader.load(
    '/models/CLOUD_01.glb',
    (gltf) =>
    {   
        gltf.scene.position.z = -20
        scene.add(gltf.scene)
    }
)

gltfLoader.load(
    '/models/CLOUD_02.glb',
    (gltf) =>
    {   
        gltf.scene.position.set(5,-10,-5)
        scene.add(gltf.scene)
    }
)

gltfLoader.load(
    '/models/CLOUD_03.glb',
    (gltf) =>
    {   
        gltf.scene.position.set(15,5,0)
        gltf.scene.rotation.y = Math.PI/2
        scene.add(gltf.scene)
    }
)

gltfLoader.load(
    '/models/CLOUD_04.glb',
    (gltf) =>
    {   
        gltf.scene.position.set(5,-5,20)
        gltf.scene.rotation.y = Math.PI
        scene.add(gltf.scene)
    }
)
gltfLoader.load(
    '/models/CLOUD_05.glb',
    (gltf) =>
    {   
        gltf.scene.position.set(-35,-2,0)
        gltf.scene.rotation.y = Math.PI/2
        scene.add(gltf.scene)
    }
)

gltfLoader.load(
    '/models/LowPolyworker.gltf',
    (gltf) =>
    {   
        const mixer = new THREE.AnimationMixer(gltf.scene)
        //const action = mixer.clipAction(gltf.animation)
        //action.play()
        //console.log(gltf.scene);
        gltf.scene.position.set(0.4,-0.07,0.9)
        gltf.scene.rotation.y = (Math.PI/180)*-45
        gltf.scene.scale.set(0.5,0.5,0.5)
        pivot.add(gltf.scene)
    }
)

/**
 * Animate
 */

const clock = new THREE.Clock()
const tick = () =>
{   
    renderer.setClearColor( backColor.color, 1 ); // the default
    directionalLightHelper.update()

    const elapsedTime = clock.getElapsedTime()
    pivot.position.y = Math.sin(elapsedTime*0.9)*0.1
    //pivot.rotation.y = elapsedTime*0.02
    controls.update();

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick();